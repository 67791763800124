@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: "Poppins";
    src: url("../public/fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: "Work Sans";
    src: url("../public/fonts/WorkSans/WorkSans-Black.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: "Work Sans Medium";
    src: url("../public/fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Work Sans SemiBold";
    src: url("../public/fonts/WorkSans/WorkSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

* {
    scrollbar-color: #d9dada #f1f1f1;
    scrollbar-width: thin;
}

::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #d9dada;
}

::-webkit-scrollbar-thumb:hover {
    background: #bcbdbd;
}

::-webkit-scrollbar-thumb:active {
    background: #4e4e4e;
}

body {
    margin: 0;
    font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.arrow,
.arrow::before {
    width: 10px;
    height: 10px;
    position: absolute;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    content: "";
    visibility: visible;
    transform: rotate(45deg);
}

.tooltip[data-popper-placement^="top"] > .arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^="bottom"] > .arrow {
    top: -4px;
}

.tooltip[data-popper-placement^="left"] > .arrow {
    right: -4px;
}

.tooltip[data-popper-placement^="right"] > .arrow {
    left: -4px;
}

.react-select-prefix__input:focus {
    box-shadow: none;
}
